import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";
import Check from "@material-ui/icons/Check";
import DevicesOther from "@material-ui/icons/DevicesOther";
import Close from "@material-ui/icons/Close";
import LockOutline from "@material-ui/icons/LockOutlined";
import LocationOn from "@material-ui/icons/LocationOn";

// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import Button from "../../../components/CustomButtons/Button.js";
import CustomInput from "../../../components/CustomInput/CustomInput.js";
import InfoArea from "../../../components/InfoArea/InfoArea.js";
import Card from "../../../components/Card/Card.js";
import CardBody from "../../../components/Card/CardBody.js";

import styles from "../../../assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import validationFormStyles from "../../../assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";

const useStyles = makeStyles(styles);
const useValidationFormStyles = makeStyles(validationFormStyles);

export default function RegisterPage() {
    const history = useHistory();
    const validationClasses = useValidationFormStyles();
    
    const [error, setError] = React.useState("Passwords must be at least 6 characters. Passwords must have at least one digit. Passwords must have at least one lowercase character. Passwords must have at least one uppercase character.");
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [EmailRequiredState, setEmailRequiredState] = React.useState("");
    const [PasswordRequiredState, setPasswordRequiredState] = React.useState("");
    const [PhoneNumberRequiredState, setPhoneNumberRequiredState] = React.useState("");
    const [ConfirmPasswordRequiredState, setConfirmPasswordRequiredState] = React.useState("");
    const [ConfirmTCRequiredState, setConfirmTCRequiredState] = React.useState("");
    const [checked, setChecked] = React.useState([]);
    const [DisbleButtons, setDisableButtons] = React.useState(false);

    const searchParams = new URLSearchParams(window.location.search);
    const params = { productCode: searchParams.get('productCode') || '', action: searchParams.get('action') || '', };

    const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");

    React.useEffect(() => {
        let id = setTimeout(function () {
            setCardAnimation("");
        }, 700);
        // Specify how to clean up after this effect:
        return function cleanup() {
            window.clearTimeout(id);
        };
    });

    useEffect(() => {
        if (params.productCode !== '') {
            if (params.action === 'add') {
                var bearerToken = localStorage.getItem("careXactSOS_BearerToken");

                if (bearerToken !== null) {
                    // redirect to the devices add device wizard if they have a login already
                    history.push("/admin/devices?action=add&productCode=" + params.productCode);
                }
            }
        }
    }, []);

    const handleToggle = value => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
            setChecked(newChecked);
    };

    const verifyLength = (value, length) => {
        if (value === null) return false;

        if (value.length >= length) {
            return true;
        }
        return false;
    };

    const verifyEmail = (value) => {
        if (value === null) return false;

        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(value)) {
            return true;
        }
        return false;
    };

    const classes = useStyles();

    function validateFormFields() {
        var result = true;

        if (email === "") {
            setEmailRequiredState("error");
            result = false;
        }
        if (confirmPassword === "") {
            setConfirmPasswordRequiredState("error");
            result = false;
        }
        if (password === "") {
            setPasswordRequiredState("error");
            result = false;
        } else {
            if (password !== confirmPassword) {
                setPasswordRequiredState("error");
                setConfirmPasswordRequiredState("error");
                result = false;
            }
        }

        if (checked[0] !== 1) {
            setConfirmTCRequiredState("error");
            result = false;
        } else {
            setConfirmTCRequiredState("success");
        }

        if (phoneNumber === "") {
            setPhoneNumberRequiredState("error");
            result = false;
        } else {
            setPhoneNumberRequiredState("success");
        }

        return result;
    }

    function formatPhoneNumber(value){
        // if input value is false eg if the user deletes the input, then just return
        if (!value) return value;

        // clean the input for any non-digit values.
        const formattedPhoneNumber = value.replace(/[^\d]/g, "");

        // phoneNumberLength is used to know when to apply our formatting for the phone number
        const phoneNumberLength = formattedPhoneNumber.length;

        if (formattedPhoneNumber.substring(0, 2) === "00") {
            return formattedPhoneNumber.substring(0, 3);
        }

        if (formattedPhoneNumber.substring(0, 2) === "04") {
            // we need to return the value with no formatting if its less then four digits
            // this is to avoid weird behavior that occurs if you  format the area code to early
            if (phoneNumberLength < 5) return formattedPhoneNumber;

            // if phoneNumberLength is greater than 4 and less the 8 we start to return
            // the formatted number
            if (phoneNumberLength < 8) {
                return `${formattedPhoneNumber.slice(0, 4)} ${formattedPhoneNumber.slice(4)}`;
            }

            // finally, if the phoneNumberLength is greater then eight, we add the last
            // bit of formatting and return it.
            return `${formattedPhoneNumber.slice(0, 4)} ${formattedPhoneNumber.slice(4, 7)} ${formattedPhoneNumber.slice(7, 10)}`;

        } else {
            if (phoneNumberLength < 3) return formattedPhoneNumber;

            if (phoneNumberLength < 7) {
                return `${formattedPhoneNumber.slice(0, 2)} ${formattedPhoneNumber.slice(2)}`;
            }

            return `${formattedPhoneNumber.slice(0, 2)} ${formattedPhoneNumber.slice(2, 6)} ${formattedPhoneNumber.slice(6, 10)}`;
        }
    }

    function handleErrors(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response.json();
    }

    const handleSignUp = async (event) => {

        if (validateFormFields() === false) {
            if (password !== confirmPassword) {
                setError("Password and Confirm password do not match.");
            }
            else {
                if (checked[0] !== 1) {
                    setError("Confirm you agree to the terms and conditions before proceeding.");
                } else {
                    setError("Update the required fields and try again.");
                }
            }
        }
        else {
            setError("");
            const json = {
                Email: email.trim(),
                Password: password,
                ConfirmPassword: confirmPassword,
                PhoneNumber: phoneNumber
            }

            setDisableButtons(true);

            const apiURL = process.env.REACT_APP_API_BASE_URL;
            const subscriptionKey = process.env.REACT_APP_API_SUBSCRIPTION_KEY;
            const headers = { 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': subscriptionKey };

            fetch(apiURL + 'api/Account/Register', {
                method: 'POST',
                body: JSON.stringify(json),
                headers: headers
            })
                .then(response => {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    else {
                        // auto login if account created successfully
                        fetch(apiURL + 'Token', {
                            method: 'POST',
                            body: "grant_type=password&username=" + email + "&password=" + password,
                            headers: headers
                        })
                            .then(handleErrors)
                            .then(response => {
                                localStorage.setItem("careXactSOS_BearerToken", response.access_token);
                                localStorage.setItem("careXactSOS_ApiURL", apiURL);
                                localStorage.setItem("careXactSOS_SubscriptionKey", subscriptionKey);

                                if (params.productCode !== '') {
                                    if (params.action === 'add') {
                                        var bearerToken = localStorage.getItem("careXactSOS_BearerToken");

                                        if (bearerToken !== null) {
                                            // redirect to the devices add device wizard now user has a login
                                            history.push("/admin/devices?action=add&productCode=" + params.productCode);
                                        }
                                    }
                                }
                                else {
                                    history.push("/");
                                }
                            })
                            .catch(error => {
                                localStorage.removeItem("careXactSOS_BearerToken");
                                setError("Something went wrong. Please check your email and password then try again, or use the reset password feature above.");
                                console.log(error.message);
                                setDisableButtons(false);
                            });
                    }
                })
                .catch(error => {
                    setError("Something went wrong. Passwords must be at least 6 characters. Passwords must have at least one digit. Passwords must have at least one lowercase character. Passwords must have at least one uppercase character.");
                    console.log(error.message);
                    setDisableButtons(false);
                });
        }
    }

    function NewlineText(props) {
        const text = props.text;
        const newText = text.split('.').map(str => <div>{str}</div>);
        return newText;
    }

    return (
        <div className={classes.container}>
        <GridContainer justify="center">
            <GridItem xs={12} sm={8} md={8}>
            <Card login className={classes[cardAnimaton]}>
            <h2 className={classes.cardTitle}>Sign Up</h2>
            <CardBody>
                {alert}
                <GridContainer justify="center">
                    <GridItem xs={12}>
                    <form className={classes.form}>
                        <CustomInput
                            labelText="Email..."
                            id="email"
                            error={EmailRequiredState === "error"}
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                onChange: event => {
                                    verifyEmail(event.target.value) === true ? setEmailRequiredState("success") : setEmailRequiredState("error");
                                    setEmail(event.target.value);
                                },
                                value: email,
                                endAdornment: EmailRequiredState === "error" ? (<InputAdornment position="end"><Close className={validationClasses.danger} /></InputAdornment>) : (<InputAdornment position="end"><Email className={classes.inputAdornmentIcon} /></InputAdornment>)
                            }}
                        />
                        <CustomInput
                            labelText="Password"
                            id="password"
                            error={PasswordRequiredState === "error"}
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                onChange: event => {
                                    verifyLength(event.target.value, 1) === true ? setPasswordRequiredState("success") : setPasswordRequiredState("error");
                                    setPassword(event.target.value);
                                },
                                endAdornment: PasswordRequiredState === "error" ? (<InputAdornment position="end"><Close className={validationClasses.danger} /></InputAdornment>) : (<InputAdornment position="end"><LockOutline className={classes.inputAdornmentIcon} /></InputAdornment>),
                                type: "password",
                                autoComplete: "off"
                            }}
                        />
                        <CustomInput
                            labelText="Confirm password"
                            id="confirmPassword"
                            error={ConfirmPasswordRequiredState === "error"}
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                onChange: event => {
                                    verifyLength(event.target.value, 1) === true ? setConfirmPasswordRequiredState("success") : setConfirmPasswordRequiredState("error");
                                    setConfirmPassword(event.target.value);
                                },
                                endAdornment: ConfirmPasswordRequiredState === "error" ? (<InputAdornment position="end"><Close className={validationClasses.danger} /></InputAdornment>) : (<InputAdornment position="end"><LockOutline className={classes.inputAdornmentIcon} /></InputAdornment>),
                                type: "password",
                                autoComplete: "off"
                            }}
                            />
                            <CustomInput
                                labelText="Account Holder Phone Number"
                                id="phoneNumberField"
                                error={PhoneNumberRequiredState === "error"}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: event => {
                                        verifyLength(event.target.value, 1) === true ? setPhoneNumberRequiredState("success") : setPhoneNumberRequiredState("error");
                                        var formattedPhoneNumber = formatPhoneNumber(event.target.value);
                                        setPhoneNumber(formattedPhoneNumber);
                                    },
                                    endAdornment: PhoneNumberRequiredState === "error" ? (<InputAdornment position="end"><Close className={validationClasses.danger} /></InputAdornment>) : (<InputAdornment position="end"><LockOutline className={classes.inputAdornmentIcon} /></InputAdornment>),
                                    type: "text",
                                    value: phoneNumber || '',
                                    autoComplete: "off"
                                }}
                            />
                    <FormControlLabel
                        classes={{
                        root: classes.checkboxLabelControl,
                        label: classes.checkboxLabel
                        }}
                        control={
                        <Checkbox
                            tabIndex={-1}
                            onClick={() => handleToggle(1)}
                            checked={checked[0]}
                            checkedIcon={
                            <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                            }}
                        />
                        }
                        label={
                        <span>
                            I agree to the{" "}
                                <a href="https://www.tunstallhealthcare.com.au/s/Tunstall-Gem4-SOS-Pendant_Customer-Terms-and-Conditions_Final_9-December-2022.pdf" target="_blank">terms and conditions</a>.
                        </span>
                        }
                    />
                    <div className={classes.left}>
                        <Button round color="blueSky" onClick={event => handleSignUp(event)} disabled={DisbleButtons===true}>Sign Up</Button>
                        <br />{error && <><small style={{ color: 'red' }}><NewlineText text={error} /></small></>}
                    </div>
                    </form>
                </GridItem>
                </GridContainer>
            </CardBody>
            </Card>
        </GridItem>
        </GridContainer>
    </div>
    );
}
