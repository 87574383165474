import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";

import AuthLayout from "./layouts/Auth.js";
import AdminLayout from "./layouts/Admin.js";
import ErrorView from "./views/Pages/ErrorPage.js";

import "./assets/scss/material-dashboard-pro-react.scss?v=1.9.0";
import "./assets/css/material-dashboard-pro-react.css?v=1.9.0";
import "./assets/css/style.css";

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

const hist = createBrowserHistory();

Bugsnag.start({
    apiKey: 'fa11e66eb64bbcb290093c904e9c0437',
    plugins: [new BugsnagPluginReact(React)],
    onError: function (event) {
        const bearerToken = localStorage.getItem("careXactSOS_BearerToken");
        const subscriptionKey = process.env.REACT_APP_API_Subscription_Key;

        if (!!bearerToken && !!subscriptionKey) {
            const myHeaders = new Headers();
            const apiURL = process.env.REACT_APP_BASE_URL;
            myHeaders.append("Authorization", `Bearer ${bearerToken}`);
            myHeaders.append("Content-Type", "application/json");

            const payload = {
                "PartyId": "",
                "ErrorNumber": "",
                "ErrorSeverity": event.severity || 'error',
                "ErrorState": "",
                "ErrorSource": event.errors[0]?.stacktrace[0]?.file || '',
                "ErrorLine": event.errors[0]?.stacktrace[0]?.lineNumber || '',
                "ErrorMessage": event.errors[0]?.errorMessage || '',
                "FailedMessage": event.errors[0]?.errorClass || '',
                "DateTimeCreated": event.device.time.toISOString()
            };

            fetch(apiURL + `/api/ErrorLog?subscription-key=${subscriptionKey}`, {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(payload),
                redirect: 'follow'
            });
        }
    }
});

const ErrorBoundary = Bugsnag.getPlugin('react');

ReactDOM.render(
    <ErrorBoundary FallbackComponent={ErrorView}>
       <Router history={hist}>
            <Switch>
                <Route path="/auth"><AuthLayout /></Route>
                <Route path="/auth/sign-up"><AuthLayout /></Route>
                <Route path="/auth/error-page"><AuthLayout /></Route>
                <Route path="/auth/access-denied"><AuthLayout /></Route>
                <ProtectedRoute path="/admin"><AdminLayout /></ProtectedRoute>
                <ProtectedRoute path="/admin/devices"><AdminLayout /></ProtectedRoute>
                <ProtectedRoute path="/admin/devices?:action&:productCode"><AdminLayout /></ProtectedRoute>
                <ProtectedRoute path="/admin/device-locator"><AdminLayout /></ProtectedRoute>
                <ProtectedRoute path="/admin/alerts"><AdminLayout /></ProtectedRoute>
                <ProtectedRoute path="/admin/support"><AdminLayout /></ProtectedRoute>
                <Route exact path="/register">
                    <Redirect exact from="/register" to={`/auth/sign-up${window.location.search}`} />
                </Route>
                <Route exact path="/">
                    <Redirect exact from="/" to="/admin/devices" />
                </Route>
                <Route path="*">
                    <Redirect from="/" to="/admin/devices" />
                </Route>
            </Switch>
        </Router>
    </ErrorBoundary>,
  document.getElementById("root")
);
