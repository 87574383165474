import React from "react";
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";

// core components
import GridContainer from "../../../../components/Grid/GridContainer.js";
import GridItem from "../../../../components/Grid/GridItem.js";
import CustomInput from "../../../../components/CustomInput/CustomInput.js";

import validationFormStyles from "../../../../assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import alertStyles from "../../../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import info from "../../../../assets/img/info.png";
import Fab from '@material-ui/core/Fab';
const infoButtonStyle = {
    margin: 0,
    top: '550',
    right: 60,
    bottom: 'auto',
    left: 'auto',
    position: 'fixed'
};

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
    },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  },
    ...validationFormStyles,
    ...alertStyles
};

class DeviceNameStep extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            DeviceName: "",
            alert: null
        };
    }

    componentDidMount() {
        var device = JSON.parse(localStorage.getItem("careXactSOS_Device"));

        if (device != undefined) {
            this.setState({
                DeviceName: device.DeviceName
            });
        } else {
            this.isValidated();
        }
    }

    sendState() {
        return this.state;
    }

    isValidated() {
        return true;
    }

    hideAlert = () => {
        this.setState({ alert: null });
    }

    showInfo(classes) {
        const image_url = "https://carexactsosprod.blob.core.windows.net/help/deviceName.jpg";

        this.setState({
            alert:
                <SweetAlert
                    style={{ display: "block", marginTop: "-100px", width: "800px" }}
                    title={""}
                    onConfirm={this.hideAlert}
                    onCancel={this.hideAlert}
                    confirmBtnCssClass={
                        classes.button + " " + classes.success
                    }>
                    <div>
                        <img src={`${image_url}?${global.Date.now()}`} height="100%" width="100%" objectfit="contain" />
                    </div>
                </SweetAlert>
        });
    }

    render() {
        const { classes } = this.props;        

        return (
            <div>
                {this.state.alert}
                <GridContainer justify="center">
                <GridItem xs={8} sm={8}>
                    <h4 className={classes.infoText}>You can give your device a new name here if you like.</h4>
                </GridItem>
                <GridItem xs={12} sm={6}>
                    <CustomInput labelText="Device Name" id="DeviceName" formControlProps={{ fullWidth: true }}
                        inputProps={{
                            onChange: event => this.setState({ DeviceName: event.target.value }),
                            type: "text",
                            maxLength: 20,
                            value: this.state.DeviceName,
                            autoComplete: "off"
                        }} />
                </GridItem>
                </GridContainer>
            </div>
        );
  }
}

DeviceNameStep.propTypes = {
    classes: PropTypes.object
};

export default withStyles(style)(DeviceNameStep);
