import React from "react";
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "../../../../components/Grid/GridContainer.js";
import GridItem from "../../../../components/Grid/GridItem.js";
import CustomInput from "../../../../components/CustomInput/CustomInput.js";

import validationFormStyles from "../../../../assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import alertStyles from "../../../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import PlacesAutocomplete, { geocodeByAddress, getLatLng, geocodeByPlaceId } from 'react-places-autocomplete';
import parser from 'australia-address-parser';

import info from "../../../../assets/img/info.png";
import Fab from '@material-ui/core/Fab';

const infoButtonStyle = {
    margin: 0,
    top: '550',
    right: 60,
    bottom: 'auto',
    left: 'auto',
    position: 'fixed'
};

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
    },
    ...alertStyles
};

const info_image_icon = new Image().src = "https://carexactsosprod.blob.core.windows.net/help/homeIcon.png";

class ContactDetailsStep extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            StreetNumber: "",
            StreetName: "",
            StreetType: "",
            Suburb: "",
            State: "",
            PostCode: "",
            Country: "",
            Active: "",
            BirthDate: "",
            AddressVal: "",
            AddressLatitude: "",
            AddressLongitude: "",
            alert: null
        };
    }

    componentDidMount() {
        var device = JSON.parse(localStorage.getItem("careXactSOS_Device"));

        if (device != undefined) {
            this.setState({
                FirstName: device.Party?.FirstName,
                LastName: device.Party?.LastName,
                PhoneNumber: device.Party?.PhoneNumber,
                AddressVal: device.Party?.StreetNumber !== "" ? device.Party?.StreetNumber + " " + device.Party?.StreetName + " " + device.Party?.StreetType + ", " + device.Party?.Suburb + " " + device.Party?.State + ", " + device.Party?.Country : "",
                StreetNumber: device.Party?.StreetNumber,
                StreetName: device.Party?.StreetName,
                StreetType: device.Party?.StreetType,
                Suburb: device.Party?.Suburb,
                State: device.Party?.State,
                PostCode: device.Party?.PostCode,
                Country: device.Party?.Country,
                Active: device.Party?.Active,
                BirthDate: device.Party?.BirthDate,
                AddressLatitude: device.Party?.AddressLatitude,
                AddressLongitude: device.Party?.AddressLongitude
            });
        } else {
            this.isValidated();
        }
    }

    sendState() {
        return this.state;
    }

    verifyLength(value, length) {
        if (value.length >= length) {
          return true;
        }
        return false;
      }

    change(event, stateName) {
        this.setState({ [stateName]: event.target.value });
    }

    isValidated() {
        return true;
    }

    handleSelect = async (address, placeId) => {
        this.setState({ AddressVal: address });

        const addressInfo = parser.parseLocation(address);

        // PlacesAutocomplete does not return a postcode
        this.setState({
            StreetNumber: addressInfo?.streetNumber || '',
            StreetName: addressInfo?.streetName || '',
            StreetType: addressInfo?.streetType || '',
            Suburb: addressInfo?.suburb || '',
            State: addressInfo?.state || '',
            Country: 'Australia'
        });

        await geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                this.setState({ AddressLatitude: latLng.lat, AddressLongitude: latLng.lng });
            })
            .catch(error => console.error('Error', error));

        var place = await geocodeByPlaceId(placeId);
        if (place != undefined)
            if (place.length > 0) {
                var postalCode = place[0].address_components.find(c => c.types.includes('postal_code')).short_name || {};
                this.setState({PostCode: postalCode});
            }
    }

    handleAddressChange = (address) => {
        this.setState({ AddressVal: address });

        if (address === "") {
            this.setState({
                StreetNumber: "",
                StreetName: "",
                StreetType: "",
                Suburb: "",
                State: "",
                PostCode: "",
                Country: "",
                AddressLatitude: "",
                AddressLongitude: ""
            });
        }
    }

    hideAlert = () => {
        this.setState({ alert: null });
    }

    showInfo(classes) {
        this.setState({
            alert:
                <SweetAlert
                    style={{ display: "block", marginTop: "-100px", width: "500px" }}
                    title={""}
                    onConfirm={this.hideAlert}
                    onCancel={this.hideAlert}
                    confirmBtnCssClass={
                        classes.button + " " + classes.success
                    }>
                    <div style={{ textAlign: "justify" }}>
                        <center><img src={info_image_icon} height="107px" width="107px" objectfit="contain" /></center><br />
                        <p>Enter the pendant wearers contact details.  This is the name, home address, and contact phone number of the person wearing the SOS pendant.</p>
                        <p>This information is optional.</p>
                    </div>
                </SweetAlert>
        });
    }

    formatPhoneNumber = (value) => {
        // if input value is false eg if the user deletes the input, then just return
        if (!value) return value;

        // clean the input for any non-digit values.
        const phoneNumber = value.replace(/[^\d]/g, "");

        // phoneNumberLength is used to know when to apply our formatting for the phone number
        const phoneNumberLength = phoneNumber.length;

        if (phoneNumber.substring(0, 2) === "00") {
            return phoneNumber.substring(0, 3);
        }

        if (phoneNumber.substring(0, 2) === "04") {
            // we need to return the value with no formatting if its less then four digits
            // this is to avoid weird behavior that occurs if you  format the area code to early
            if (phoneNumberLength < 5) return phoneNumber;

            // if phoneNumberLength is greater than 4 and less the 8 we start to return
            // the formatted number
            if (phoneNumberLength < 8) {
                return `${phoneNumber.slice(0, 4)} ${phoneNumber.slice(4)}`;
            }

            // finally, if the phoneNumberLength is greater then eight, we add the last
            // bit of formatting and return it.
            return `${phoneNumber.slice(0, 4)} ${phoneNumber.slice(4, 7)} ${phoneNumber.slice(7, 10)}`;

        } else {
            if (phoneNumberLength < 3) return phoneNumber;

            if (phoneNumberLength < 7) {
                return `${phoneNumber.slice(0, 2)} ${phoneNumber.slice(2)}`;
            }

            return `${phoneNumber.slice(0, 2)} ${phoneNumber.slice(2, 6)} ${phoneNumber.slice(6, 10)}`;
        }
    }

    render() {
        const { classes } = this.props;        

        return (
            <div>
                <Fab size="small" color="white" aria-label="add" style={infoButtonStyle}>
                    <img src={info} height="45" width="45" onClick={event => this.showInfo(classes)} />
                </Fab>
                {this.state.alert}
                <GridContainer justify="center">
                    <GridItem xs={8}>      
                        <h4 className={classes.infoText}>Contact details for the pendant wearer</h4>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} lg={6}>
                        <CustomInput labelText="First Name" id="FirstName" formControlProps={{ fullWidth: false }}
                            inputProps={{
                                onChange: event => this.change(event, "FirstName"),
                                type: "text",
                                value: this.state.FirstName || '',
                                autoComplete: "off"
                                }} />
                         <CustomInput labelText="Last Name" id="LastName" formControlProps={{ fullWidth: false }}
                            inputProps={{
                                onChange: event => this.change(event, "LastName"),
                                type: "text",
                                value: this.state.LastName || '',
                                autoComplete: "off"
                            }} />
                    </GridItem>
                    <GridItem xs={12} />
                    <GridItem xs={12} sm={6} md={6} lg={6}>
                        <CustomInput labelText="Phone" id="PhoneNumber" formControlProps={{ fullWidth: false }}
                            inputProps={{
                                onChange: event => this.setState({PhoneNumber: this.formatPhoneNumber(event.target.value)}),
                                type: "text",
                                value: this.state.PhoneNumber || '',
                                autoComplete: "off"
                            }} />
                    </GridItem>
                    <GridItem xs={12} />
                    <GridItem xs={12} sm={6} md={6} lg={6}>
                        <PlacesAutocomplete fetchDetails={true} value={this.state.AddressVal} onChange={val => this.handleAddressChange(val)} onSelect={this.handleSelect} debounce={500} searchOptions={{ types: ['address'], componentRestrictions: { country: 'au' }, }}>
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                                    <input type="text" name="address" autoComplete="off" style={{
                                    paddingTop: '15px',
                                    border: "none", borderBottom: "1px solid lightgray", fontSize: "14px",
                                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                    fontWeight: "400",
                                    lineHeight: "1.42857",
                                    opacity: "1",
                                    color: "#495057",
                                    width: "100%"}}
                                    {...getInputProps({
                                        placeholder: 'Type address ...',
                                        className: 'contact-details-step'
                                    })}
                                />
                                <div>
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map(suggestion => {
                                        const className = `py-1 px-2 ${suggestion.active ? 'suggestion-item--active' : 'suggestion-item'}`;
                                        // inline style for demonstration purpose
                                        const style = suggestion.active ? { backgroundColor: '#fafafa', cursor: 'pointer' } : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                        return (
                                            <div {...getSuggestionItemProps(suggestion, {className, style,})}>
                                                <span>{suggestion.description}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </PlacesAutocomplete>
                </GridItem>
                </GridContainer>
            </div>
        );
  }
}

ContactDetailsStep.propTypes = {
    classes: PropTypes.object
};

export default withStyles(style, validationFormStyles)(ContactDetailsStep);
