import React from "react";
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// core components
import GridContainer from "../../../../components/Grid/GridContainer.js";
import GridItem from "../../../../components/Grid/GridItem.js";

import customSelectStyle from "../../../../assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import extendedFormStyles from "../../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import alertStyles from "../../../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import info from "../../../../assets/img/info.png";
import Fab from '@material-ui/core/Fab';
const infoButtonStyle = {
    margin: 0,
    top: '550',
    right: 60,
    bottom: 'auto',
    left: 'auto',
    position: 'fixed'
};

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
    ...customSelectStyle,
    ...extendedFormStyles,
    ...alertStyles
};

const info_image_icon = new Image().src = "https://carexactsosprod.blob.core.windows.net/help/emergencyContactIcon.png";
const info_image = new Image().src = "https://carexactsosprod.blob.core.windows.net/help/emergencyContacts.jpg";

class EmergencyContactsStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        PhoneNumber1: "",
        PhoneNumber1ReceiveCalls: false,
        PhoneNumber1ReceiveSMS: false,
        PhoneNumber2: "",
        PhoneNumber2ReceiveCalls: false,
        PhoneNumber2ReceiveSMS: false,
        PhoneNumber3: "",
        PhoneNumber3ReceiveCalls: false,
        PhoneNumber3ReceiveSMS: false,
        PhoneNumber4: "",
        PhoneNumber4ReceiveCalls: false,
        PhoneNumber4ReceiveSMS: false,
        alert: null
    };
  }

    sendState() {
        return this.state;
    }

    hideAlert = () => {
        this.setState({alert: null});
    }

    showInfo(classes) {
        this.setState({
            alert:
                <SweetAlert
                    style={{ display: "block", marginTop: "-100px", width: "500px" }}
                    title={""}
                    onConfirm={this.hideAlert}
                    onCancel={this.hideAlert}
                    confirmBtnCssClass={
                        classes.button + " " + classes.success
                    }>
                    <div style={{ textAlign: "justify" }}>
                        <center><img src={info_image_icon} height="107px" width="107px" objectfit="contain" /></center><br />
                        <img src={`${info_image}?${global.Date.now()}`} height="100%" width="100%" objectfit="contain" /><br/>
                        <ul>
                            <li>Enter the most important phone number first.  It will be called first.</li>
                            <li>When a fall is detected, or the SOS button is pressed, phone numbers will be called one at a time in order from 1 through 4.  This call sequence will repeat 3 times until the SOS emergency is cancelled by the pendant wearer.</li>
                            <li>Only phone numbers with <b><i>Receive Call On</i></b> will receive a call.  Phone numbers with <b><i>Receive Call Off</i></b> will be skipped.</li>
                            <li>Phone numbers with <b><i>Receive SMS On</i></b> will receive an immediate emergency SMS alert notification.</li>
                            <li>Additionally, phone numbers with <b><i>Receive SMS On</i></b> will receive battery low, device offline, no signal, and device configuration change SMS notifications.</li>
                        </ul>
                    </div>
                </SweetAlert>
        });
    }

    showAlert(classes, title, message) {
        this.setState({
            alert:
                <SweetAlert
                    error
                    style={{ display: "block", marginTop: "-100px", width: "400px" }}
                    title={title}
                    onConfirm={this.hideAlert}
                    onCancel={this.hideAlert}
                    confirmBtnCssClass={
                        classes.button + " " + classes.success
                    }>
                    <div>
                        { message }
                    </div>
                </SweetAlert>
        });
    }

    componentDidMount() {
        var device = JSON.parse(localStorage.getItem("careXactSOS_Device"));

        if (device != undefined) {
            if (device.AuthorizedNumbers !== null) {
                if (device.AuthorizedNumbers !== undefined) {
                    var i = 0;
                    for (i = 0; i < device.AuthorizedNumbers.length; i++) {
                        var AuthorizedNumber = device.AuthorizedNumbers[i];

                        if (AuthorizedNumber.Serial === 0) {
                            this.setState({
                                PhoneNumber1: this.formatPhoneNumber(AuthorizedNumber.AuthorizedNumber),
                                PhoneNumber1ReceiveCalls: AuthorizedNumber.AcceptPhoneCall === "Y" ? true : false,
                                PhoneNumber1ReceiveSMS: AuthorizedNumber.AcceptSMS === "Y" ? true : false
                            });
                        }
                        if (AuthorizedNumber.Serial === 1) {
                            this.setState({
                                PhoneNumber2: this.formatPhoneNumber(AuthorizedNumber.AuthorizedNumber),
                                PhoneNumber2ReceiveCalls: AuthorizedNumber.AcceptPhoneCall === "Y" ? true : false,
                                PhoneNumber2ReceiveSMS: AuthorizedNumber.AcceptSMS === "Y" ? true : false
                            });
                        }
                        if (AuthorizedNumber.Serial === 2) {
                            this.setState({
                                PhoneNumber3: this.formatPhoneNumber(AuthorizedNumber.AuthorizedNumber),
                                PhoneNumber3ReceiveCalls: AuthorizedNumber.AcceptPhoneCall === "Y" ? true : false,
                                PhoneNumber3ReceiveSMS: AuthorizedNumber.AcceptSMS === "Y" ? true : false
                            });
                        }
                        if (AuthorizedNumber.Serial === 3) {
                            this.setState({
                                PhoneNumber4: this.formatPhoneNumber(AuthorizedNumber.AuthorizedNumber),
                                PhoneNumber4ReceiveCalls: AuthorizedNumber.AcceptPhoneCall === "Y" ? true : false,
                                PhoneNumber4ReceiveSMS: AuthorizedNumber.AcceptSMS === "Y" ? true : false
                            });
                        }
                    }
                }
            }
        }
    }

    isValidated() {
        var result = false;

        if (this.state.PhoneNumber1.trim().length === 0 && this.state.PhoneNumber2.trim().length === 0 && this.state.PhoneNumber3.trim().length === 0 && this.state.PhoneNumber4.trim().length === 0) {
            result = false;
        }
        else {

            if (this.state.PhoneNumber1ReceiveCalls || this.state.PhoneNumber1ReceiveSMS)
                if (this.isValidPhoneNumber(this.state.PhoneNumber1.trim()))
                    result = true;

            if (result !== true)
                if (this.state.PhoneNumber2ReceiveCalls || this.state.PhoneNumber2ReceiveSMS)
                    if (this.isValidPhoneNumber(this.state.PhoneNumber2.trim()))
                        result = true;

            if (result !== true)
                if (this.state.PhoneNumber3ReceiveCalls || this.state.PhoneNumber3ReceiveSMS)
                    if (this.isValidPhoneNumber(this.state.PhoneNumber3.trim())) 
                        result = true

            if (result !== true)
                if (this.state.PhoneNumber4ReceiveCalls || this.state.PhoneNumber4ReceiveSMS)
                    if (this.isValidPhoneNumber(this.state.PhoneNumber4.trim()))
                        result = true;
        }

        if (result)
            result = this.verifyNumber(this.state.PhoneNumber1.trim());
        if (result)
            result = this.verifyNumber(this.state.PhoneNumber2.trim());
        if (result)
            result = this.verifyNumber(this.state.PhoneNumber3.trim());
        if (result)
            result = this.verifyNumber(this.state.PhoneNumber4.trim());

        if (result === false) {
            const { classes } = this.props;
            this.showAlert(classes, "Invalid Phone Number", "At least one valid phone number with either Receive Call enabled or Receive SMS enabled must be entered.  Valid phone numbers start with 02, 03, 04, 07, 08.  Country codes such as +61 are not allowed.  000 is allowed.")
        }

        return result;
    }

    verifyNumber(value){
        if (value === "") return true;

        var valueWithoutSpaces = value.replace(/ /g, '');

        var numberRex = new RegExp("^[0-9]+$");
        if (numberRex.test(valueWithoutSpaces)) {
            return true;
        }
        return false;
    }

    isValidPhoneNumber(phoneNumber) {
        var result = false;
        
        if (phoneNumber.substring(0, 2) === "02") result = true;
        if (phoneNumber.substring(0, 2) === "03") result = true;
        if (phoneNumber.substring(0, 2) === "04") result = true;
        if (phoneNumber.substring(0, 2) === "07") result = true;
        if (phoneNumber.substring(0, 2) === "08") result = true;
        if (phoneNumber === "000") result = true;

        return result;
    }

    formatPhoneNumber = (value) => {
        // if input value is false eg if the user deletes the input, then just return
        if (!value) return value;

        // clean the input for any non-digit values.
        const phoneNumber = value.replace(/[^\d]/g, "");

        // phoneNumberLength is used to know when to apply our formatting for the phone number
        const phoneNumberLength = phoneNumber.length;

        if (phoneNumber.substring(0, 2) === "00") {
            return phoneNumber.substring(0, 3);
        }

        if (phoneNumber.substring(0, 2) === "04") {
            // we need to return the value with no formatting if its less then four digits
            // this is to avoid weird behavior that occurs if you  format the area code to early
            if (phoneNumberLength < 5) return phoneNumber;

            // if phoneNumberLength is greater than 4 and less the 8 we start to return
            // the formatted number
            if (phoneNumberLength < 8) {
                return `${phoneNumber.slice(0, 4)} ${phoneNumber.slice(4)}`;
            }

            // finally, if the phoneNumberLength is greater then eight, we add the last
            // bit of formatting and return it.
            return `${phoneNumber.slice(0, 4)} ${phoneNumber.slice(4, 7)} ${phoneNumber.slice(7, 10)}`;

        } else {
            if (phoneNumberLength < 3) return phoneNumber;

            if (phoneNumberLength < 7) {
                return `${phoneNumber.slice(0, 2)} ${phoneNumber.slice(2)}`;
            }

            return `${phoneNumber.slice(0, 2)} ${phoneNumber.slice(2, 6)} ${phoneNumber.slice(6, 10)}`;
        }
    }

    render() {
      const { classes } = this.props;
      
        return (
            <div>
                <Fab size="small" color="white" aria-label="add" style={infoButtonStyle}>
                    <img src={info} height="45" width="45" onClick={event => this.showInfo(classes)} />
                </Fab>
            <GridContainer justify="center">
                {this.state.alert}
                <GridItem xs={8} sm={8} md={8} lg={8}>
                    <h4 className={classes.infoText}>Set emergency contacts for your pendant</h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12} />
                    <table>
                      <thead>
                        <tr>
                            <th></th>
                            <th style={{ fontSize: "12px" }}>Phone Number</th>
                            <th style={{ fontSize: "12px" }}>Receive Call</th>
                            <th style={{ fontSize: "12px" }}>Receive SMS</th>
                          </tr>
                      </thead>
                      <tbody>
                        <tr>
                              <td>1</td>
                              <td>
                                  <input type="text" name="PhoneNumber1" autoComplete="off" value={this.state.PhoneNumber1} onChange={event => this.setState({ PhoneNumber1: this.formatPhoneNumber(event.target.value) })} style={{
                                      border: "none", borderBottom: "1px solid lightgray", fontSize: "12px",
                                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                      fontWeight: "400",
                                      lineHeight: "1.42857",
                                      opacity: "1",
                                      color: "#495057",
                                      width: "90px"
                                  }}/>
                              </td>
                              <td>
                                    <FormControlLabel control={<Switch checked={this.state.PhoneNumber1ReceiveCalls} onChange={e => this.setState({ PhoneNumber1ReceiveCalls: e.target.checked })} value="NoActivityAlertEnabled" classes={{ switchBase: classes.switchBase, checked: classes.switchChecked, thumb: classes.switchIcon, track: classes.switchBar }} />} classes={{ label: classes.label }} label={this.state.PhoneNumber1ReceiveCalls ? "On " : "Off"} />
                              </td>
                              <td>
                                    <FormControlLabel control={<Switch checked={this.state.PhoneNumber1ReceiveSMS} onChange={e => this.setState({ PhoneNumber1ReceiveSMS: e.target.checked })} value="NoActivityAlertEnabled" classes={{ switchBase: classes.switchBase, checked: classes.switchChecked, thumb: classes.switchIcon, track: classes.switchBar }} />} classes={{ label: classes.label }} label={this.state.PhoneNumber1ReceiveSMS ? "On " : "Off"} />
                              </td>
                          </tr>
                          <tr>
                              <td>2</td>
                              <td><input type="text" name="PhoneNumber2" autoComplete="off" value={this.state.PhoneNumber2} onChange={event => this.setState({ PhoneNumber2: this.formatPhoneNumber(event.target.value) })} style={{
                                      border: "none", borderBottom: "1px solid lightgray", fontSize: "12px",
                                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                      fontWeight: "400",
                                      lineHeight: "1.42857",
                                      opacity: "1",
                                      color: "#495057",
                                      width: "90px"
                                  }}/></td>
                              <td>
                                    <FormControlLabel control={<Switch checked={this.state.PhoneNumber2ReceiveCalls} onChange={e => this.setState({ PhoneNumber2ReceiveCalls: e.target.checked })} value="NoActivityAlertEnabled" classes={{ switchBase: classes.switchBase, checked: classes.switchChecked, thumb: classes.switchIcon, track: classes.switchBar }} />} classes={{ label: classes.label }} label={this.state.PhoneNumber2ReceiveCalls ? "On " : "Off"} />
                              </td>
                              <td>
                                    <FormControlLabel control={<Switch checked={this.state.PhoneNumber2ReceiveSMS} onChange={e => this.setState({ PhoneNumber2ReceiveSMS: e.target.checked })} value="NoActivityAlertEnabled" classes={{ switchBase: classes.switchBase, checked: classes.switchChecked, thumb: classes.switchIcon, track: classes.switchBar }} />} classes={{ label: classes.label }} label={this.state.PhoneNumber2ReceiveSMS ? "On " : "Off"} />
                              </td>
                          </tr>
                            <tr>
                              <td>3</td>
                              <td><input type="text" name="PhoneNumber3" autoComplete="off" value={this.state.PhoneNumber3} onChange={event => this.setState({ PhoneNumber3: this.formatPhoneNumber(event.target.value) })} style={{
                                      border: "none", borderBottom: "1px solid lightgray", fontSize: "12px",
                                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                      fontWeight: "400",
                                      lineHeight: "1.42857",
                                      opacity: "1",
                                      color: "#495057",
                                      width: "90px"
                                  }}/></td>
                              <td>
                                    <FormControlLabel control={<Switch checked={this.state.PhoneNumber3ReceiveCalls} onChange={e => this.setState({ PhoneNumber3ReceiveCalls: e.target.checked })} value="NoActivityAlertEnabled" classes={{ switchBase: classes.switchBase, checked: classes.switchChecked, thumb: classes.switchIcon, track: classes.switchBar }} />} classes={{ label: classes.label }} label={this.state.PhoneNumber3ReceiveCalls ? "On " : "Off"} />
                              </td>
                              <td>
                                    <FormControlLabel control={<Switch checked={this.state.PhoneNumber3ReceiveSMS} onChange={e => this.setState({ PhoneNumber3ReceiveSMS: e.target.checked })} classes={{ switchBase: classes.switchBase, checked: classes.switchChecked, thumb: classes.switchIcon, track: classes.switchBar }} />} classes={{ label: classes.label }} label={this.state.PhoneNumber3ReceiveSMS ? "On " : "Off"} />
                              </td>
                          </tr>
                            <tr>
                              <td>4</td>
                              <td><input type="text" name="PhoneNumber4" autoComplete="off" value={this.state.PhoneNumber4} onChange={event => this.setState({ PhoneNumber4: this.formatPhoneNumber(event.target.value) })} style={{
                                      border: "none", borderBottom: "1px solid lightgray", fontSize: "12px",
                                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                      fontWeight: "400",
                                      lineHeight: "1.42857",
                                      opacity: "1",
                                      color: "#495057",
                                      width: "90px"
                                  }}/></td>
                              <td>
                                    <FormControlLabel control={<Switch checked={this.state.PhoneNumber4ReceiveCalls} onChange={e => this.setState({ PhoneNumber4ReceiveCalls: e.target.checked })} value="NoActivityAlertEnabled" classes={{ switchBase: classes.switchBase, checked: classes.switchChecked, thumb: classes.switchIcon, track: classes.switchBar }} />} classes={{ label: classes.label }} label={this.state.PhoneNumber4ReceiveCalls ? "On " : "Off"} />
                              </td>
                              <td>
                                    <FormControlLabel control={<Switch checked={this.state.PhoneNumber4ReceiveSMS} onChange={e => this.setState({ PhoneNumber4ReceiveSMS: e.target.checked })} value="NoActivityAlertEnabled" classes={{ switchBase: classes.switchBase, checked: classes.switchChecked, thumb: classes.switchIcon, track: classes.switchBar }} />} classes={{ label: classes.label }} label={this.state.PhoneNumber4ReceiveSMS ? "On " : "Off"} />
                              </td>
                          </tr>
                      </tbody>
                </table>
            </GridContainer>
            </div>
    );
  }
}

EmergencyContactsStep.propTypes = {
    classes: PropTypes.object
};

export default withStyles(style, alertStyles)(EmergencyContactsStep);
